import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    paddingBottom: '8rem',
    paddingTop: '80px',
  },
  [theme.breakpoints.down('md')]: {
    contentWrapper: {
      paddingTop: '65px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    contentWrapper: {
      paddingTop: '60px',
    },
  },
}));

const ContentWrapper = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.contentWrapper}>{children}</div>;
};

ContentWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ContentWrapper;
