import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, Slide } from '@material-ui/core';

import Backdrop from './navigation/components/Backdrop';

const ModalOverlay = ({ show, children }) => {
  const classes = useStyles();
  return (
    <Slide direction='up' in={show} mountOnEnter>
      <div className={classes.modal}>
        <div className={classes.modalBody}>{children}</div>
      </div>
    </Slide>
  );
};

const Modal = ({ show, onCancel, onSubmit, children, ...props }) => {
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useState(true);

  useEffect(() => {
    if (show) setModalVisible(true);
  }, [show, setModalVisible]);

  const backdropClickHandler = () => {
    setModalVisible(false);
    setTimeout(function () {
      onCancel();
    }, theme.transitions.duration.standard);
  };
  return (
    <Fragment>
      {show && (
        <Backdrop onClick={backdropClickHandler}>
          <ModalOverlay show={modalVisible} children={children} {...props} />
        </Backdrop>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'relative',
    minHeight: '17vh',
    background: 'white',
    boxShadow: '0 8px 8px rgba(0,0,0,0.9)',
    border: '2px solid grey',
    maxWidth: '100%',
    backgroundColor: 'white',
    zIndex: 108,
    margin: 'auto',
    minWidth: '28rem',
  },
  [theme.breakpoints.down('xs')]: {
    modal: {
      width: '24rem',
      minWidth: '24rem',
    },
  },
  [theme.breakpoints.down(450)]: {
    modal: {
      width: '21rem',
      minWidth: '21rem',
    },
  },
  [theme.breakpoints.down(400)]: {
    modal: {
      width: '18rem',
      minWidth: '18rem',
    },
  },
}));

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Modal;
