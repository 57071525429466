import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Modal from '../../shared/Modal';
import ConfirmWeeklyPaymentModalContent from './ConfirmWeeklyPaymentModalContent';
import ConfirmUpfrontPaymentModalContent from './ConfirmUpfrontPaymentModalContent';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  discountContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5rem 2rem',
  },
  textContainer: {
    textAlign: 'left',
    fontSize: '1rem',
    '& p': {
      margin: '1.5rem auto',
    },
  },
  buttonRow: {
    display: 'flex',
    margin: '1.2rem auto',
  },
  nutritionHeader: {
    fontSize: '1.9rem',
    color: theme.palette.lifestyle.purple,
    fontWeight: 900,
    margin: '1rem auto',
    textAlign: 'center',
  },
  paymentOptionButton: {
    textTransform: 'none',
    height: '3.5rem',
    backgroundColor: theme.palette.lifestyle.purple,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '20px',
    fontSize: '1.5rem',
    margin: '0 .3rem',
    padding: '1rem',
    transition: theme.transitions.create(['color', 'background-color'], {
      duration: theme.transitions.duration.complex,
    }),
    '& :hover': {
      color: theme.palette.lifestyle.purple,
      '& span': {
        color: theme.palette.lifestyle.purple,
      },
    },
  },
  sectionDivider: {
    position: 'relative',
    ...theme.CSS.center,
    width: '100%',
    marginTop: '2rem',
  },
  orDiv: {
    position: 'absolute',
    backgroundColor: theme.palette.general.body,
    width: '4rem',
    height: '3rem',
    fontSize: '2rem',
    zIndex: 9,
    ...theme.CSS.center,
  },
  terms: {
    fontSize: '.9rem',
    margin: '1rem auto',
    display: 'inline',
  },
  line: {
    height: '1px',
    width: '100%',
    backgroundColor: 'black',
    position: 'absolute',
  },
  inlineLink: {
    display: 'inline',
  },
  termsContainer: {
    display: 'inline',
  },
  [theme.breakpoints.down('lg')]: {
    paymentOptionButton: {
      fontSize: '1.2rem',
      fontWeight: 900,
    },
  },
  [theme.breakpoints.down('sm')]: {
    buttonRow: {
      flexDirection: 'column',
    },
    paymentOptionButton: {
      fontSize: '1.2rem',
      margin: '1rem auto',
    },
    discountContainer: {
      margin: '1rem 2rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    paymentOptionButton: {
      fontSize: '1.2rem',
    },
    discountContainer: {
      margin: '1rem .8rem',
    },
    nutritionHeader: {
      fontSize: '1.6rem',
    },
  },
  [theme.breakpoints.down(470)]: {
    paymentOptionButton: {
      fontSize: '.9rem',
      height: '3rem',
    },
    discountContainer: {
      margin: '1rem .2rem',
    },
  },
}));

const LifestyleDiscountSection = () => {
  const { success } = useSelector((state) => state.discount);
  const [redirect, setRedirect] = useState(false);
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    weeklyPayment: false,
    upfrontPayment: false,
  });

  // If user dismisses modal and membership has been successfully switched, redirect:
  useEffect(() => {
    if (
      success &&
      modalState.weeklyPayment === false &&
      modalState.upfrontPayment === false
    ) {
      setRedirect(true);
    }
  }, [success, modalState]);

  const closeModalHandler = () => {
    setModalState({
      weeklyPayment: false,
      upfrontPayment: false,
    });
  };
  if (redirect) {
    window.location.href = process.env.REACT_APP_GO_LOGIN;
  }
  return (
    <>
      <div className={classes.discountContainer}>
        <h2 className={classes.nutritionHeader}>
          30% OFF&#42; LIFESTYLE MEMBERSHIP
        </h2>
        <div className={classes.textContainer}>
          <p>
            Love your Lifestyle Membership but finding the weekly cost a bit
            tricky to fit into your budget?
          </p>
          <p>
            We would like to offer you access to 12WBT Lifestyle for 30%
            off&#42; for the next 6 months!
          </p>
          <p>
            <b>That&apos;s just $6.99 a week or $181 upfront!</b>
          </p>
        </div>
        <div className={classes.buttonRow}>
          <Button
            className={classes.paymentOptionButton}
            onClick={() =>
              setModalState({ ...modalState, weeklyPayment: true })
            }
          >
            <span>I&apos;d like to pay weekly</span>
          </Button>
          <Button
            className={classes.paymentOptionButton}
            onClick={() =>
              setModalState({ ...modalState, upfrontPayment: true })
            }
          >
            I&apos;d like to pay 6 months upfront
          </Button>
        </div>
        <div className={classes.termsContainer}>
          <p className={classes.terms}>
            &#42;This 30% off Lifestyle offer is a minimum 6 month commitment
            and is not in conjunction with any other offer. <br />
            <br /> After the minimum term payments will revert back to $9.99 per
            week. You can schedule the cancellation of your membership at
            anytime, however your membership will remain active until the
            minimum term has been reached. See terms&nbsp;
          </p>
          <Link to='/terms/6-months-lifestyle' className={classes.inlineLink}>
            here.
          </Link>
        </div>
        <div className={classes.sectionDivider}>
          <div className={classes.orDiv}>
            <span>OR</span>
          </div>
          <div className={classes.line}></div>
        </div>
      </div>
      <Modal show={modalState.weeklyPayment} onCancel={closeModalHandler}>
        <ConfirmWeeklyPaymentModalContent />
      </Modal>
      <Modal show={modalState.upfrontPayment} onCancel={closeModalHandler}>
        <ConfirmUpfrontPaymentModalContent />
      </Modal>
    </>
  );
};

export default LifestyleDiscountSection;
