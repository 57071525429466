import React from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core';

const Backdrop = ({ onClick, children }) => {
  const classes = useStyles();
  return ReactDOM.createPortal(
    <div
      className={classes.backdrop}
      onClick={onClick}
      data-testid='backdrop-testid'
    >
      <div className={classes.innerContent}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('backdrop-hook')
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.74)',
    zIndex: 99,
    ...theme.CSS.center,
  },
  innerContent: {
    position: 'relative',
    zIndex: 100,
    display: 'fixed',
    width: 'min-content',
  },
}));

export default Backdrop;
