import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  error: {
    margin: '0 auto',
    color: 'red',
    textAlign: 'center',
  },
}));

const Error = ({ error }) => {
  const classes = useStyles();
  return <p className={classes.error}>{error}</p>;
};

Error.propTypes = {
  error: PropTypes.string,
};

export default Error;
