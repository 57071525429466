import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

import CustomButton from '../../shared/CustomButton';
import ContentContainer from '../../shared/ContentContainer';

import { useDispatch } from 'react-redux';
import { progressStep } from '../../../actions/lifestyleCancellation';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '1rem 2rem',
    fontSize: '1.05rem',
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      margin: '1rem 0.8rem',
      fontSize: '1rem',
    },
  },
}));

function buttonStyle(theme) {
  return {
    backgroundColor: theme.palette.lifestyle.darkBlue,
    margin: '1rem auto',
    fontSize: '1.3rem',
    mobileFontSize: '1.1rem',
    mobileMaxWidth: '100%',
    mobileWidth: '100%',
    lineHeight: '1.4rem',
  };
}

const ConfirmLifestyleCancellation = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  return (
    <ContentContainer>
      <div className={classes.container}>
        <span>Still want to cancel?</span>
        <CustomButton
          customStyles={buttonStyle(theme)}
          onClick={() => dispatch(progressStep())}
        >
          Continue to Cancellation
        </CustomButton>
      </div>
    </ContentContainer>
  );
};

export default ConfirmLifestyleCancellation;
