export const config = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'api.12wbt.com',
  },
};

export const RESET_STORE = 'RESET_STORE';

export const INIT_AXIOS_REQUEST = 'INIT_AXIOS_REQUEST';

// Actions for alert reducer
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Actions for prospective member
export const SET_REGISTER_INTENT = 'SET_REGISTER_INTENT';
export const REGISTER_PROSPECTIVE_MEMBER = 'REGISTER_PROSPECTIVE_MEMBER';
export const REGISTER_PROSPECTIVE_MEMBER_FAIL =
  'REGISTER_PROSPECTIVE_MEMBER_FAIL';

// Actions for lifestyle cancellation:
export const SWITCH_TO_LIFESTYLE_NUTRITION_SUCCESS =
  'SWITCH_TO_LIFESTYLE_NUTRITION_SUCCESS';
export const CONFIRM_CANCELLATION_SUCCESS = 'CONFIRM_CANCELLATION_SUCCESS';
export const INCREMENT_CANCELLATION_STEP = 'INCREMENT_CANCELLATION_STEP';
export const GET_LIFESTYLE_MEMBERSHIP_REQUEST =
  'GET_LIFESTYLE_MEMBERSHIP_REQUEST';
export const GET_LIFESTYLE_MEMBERSHIP_SUCCESS =
  'GET_LIFESTYLE_MEMBERSHIP_SUCCESS';
export const GET_LIFESTYLE_MEMBERSHIP_FAIL = 'GET_LIFESTYLE_MEMBERSHIP_FAIL';
export const UPDATE_CANCELLATION_SUCCESS = 'UPDATE_CANCELLATION_SUCCESS';

export const CREATE_LIFESTYLE_CANCELLATION_REQUEST =
  'CREATE_LIFESTYLE_CANCELLATION_REQUEST';
export const CREATE_LIFESTYLE_CANCELLATION_SUCCESS =
  'CREATE_LIFESTYLE_CANCELLATION_SUCCESS';
export const CREATE_LIFESTYLE_CANCELLATION_FAIL =
  'CREATE_LIFESTYLE_CANCELLATION_FAIL';

export const UPDATE_CANCELLATION_REQUEST = 'UPDATE_CANCELLATION_REQUEST';
export const UPDATE_CANCELLATION_FAIL = 'UPDATE_CANCELLATION_FAIL';
export const SWITCH_TO_LIFESTYLE_NUTRITION_FAIL =
  'SWITCH_TO_LIFESTYLE_NUTRITION_FAIL';

export const DISCOUNT_ON_CANCELLATION_REQUEST =
  'DISCOUNT_ON_CANCELLATION_REQUEST';
export const DISCOUNT_ON_CANCELLATION_SUCCESS =
  'DISCOUNT_ON_CANCELLATION_SUCCESS';
export const DISCOUNT_ON_CANCELLATION_FAIL = 'DISCOUNT_ON_CANCELLATION_FAIL';
