import React from 'react';
import { useTheme } from '@material-ui/core';

import CustomButton from '../../shared/CustomButton';

import { useSelector, useDispatch } from 'react-redux';
import { switchToNutrition } from '../../../actions/lifestyleCancellation';

function buttonStyle(theme) {
  return {
    mobileWidth: '100%',
    margin: '0.2rem auto',
    backgroundColor: theme.palette.lifestyle.darkBlue,
    fontSize: '1.1rem',
    lineHeight: '1.1rem',
    mobileFontSize: '1rem',
  };
}

const SwitchModalContentConfirm = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { lifestyleMember, loading } = useSelector(
    (state) => state.lifestyleCancellation
  );

  const submitSwitchToNutrition = () => {
    if (loading) return;
    const values = {
      hashToken: lifestyleMember.hashToken,
      email: lifestyleMember.email,
      name: lifestyleMember.name,
      memberId: lifestyleMember.memberId,
      membership: lifestyleMember.membership,
      membershipCancellationId: lifestyleMember._id,
      chargifySubscriptionId: lifestyleMember.chargifySubscriptionId,
    };
    dispatch(switchToNutrition(values));
  };

  return (
    <div>
      <p>
        Great! We’ll switch you over to Lifestyle Nutrition now! Your Lifestyle
        membership will be cancelled automatically and your next payment will
        debited at the new price of $4 per week.
      </p>
      <p>12WBT Team. Xx</p>
      <CustomButton
        customStyles={buttonStyle(theme)}
        onClick={() => submitSwitchToNutrition()}
        isLoading={loading}
        disabled={loading}
      >
        Confirm and Proceed
      </CustomButton>
    </div>
  );
};

export default SwitchModalContentConfirm;
