import { createMuiTheme } from '@material-ui/core/styles';
import ArsMaquetteWoff from './assets/fonts/arsmaquettepro-regular.woff';
import ArsMaquetteBoldWoff from './assets/fonts/arsmaquettepro-bold.woff';
import ArsMaquetteBlackWoff from './assets/fonts/arsmaquettepro-black.woff';
import { grey } from '@material-ui/core/colors';

const ArsMaquettePro = {
  fontFamily: 'ArsMaquettePro',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('ArsMaquettePro'),
    local('ArsMaquettePro-regular'),
    url(${ArsMaquetteWoff}) format('woff')
  `,
};

const ArsMaquetteProBlack = {
  fontFamily: 'ArsMaquettePro',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 900,
  src: `
    local('ArsMaquettePro'),
    local('ArsMaquettePro-black'),
    url(${ArsMaquetteBlackWoff}) format('woff')
  `,
};

const ArsMaquetteProBold = {
  fontFamily: 'ArsMaquettePro',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('ArsMaquettePro'),
    local('ArsMaquettePro-bold'),
    url(${ArsMaquetteBoldWoff}) format('woff')
  `,
};

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1900,
    },
  },

  typography: {
    fontFamily: [
      'ArsMaquettePro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [ArsMaquettePro, ArsMaquetteProBlack, ArsMaquetteProBold],
        body: {
          padding: '0!important',
        },
      },
    },
  },
  palette: {
    lifestyle: {
      lightBlue: '#4ca5ff',
      darkBlue: '#003b70',
      lightGreen: '#39ced9',
      nutritionBlue: '#007DE0',
      mediumBlue: '#034D93',
      success: '#4feb34',
      lightGrey: '#F5F5F6',
      purple: '#4f5fec',
    },
    RGB: {
      nutritionBlue: 'rgba(0, 125, 224, 0.4)',
      purple: 'rgba(79, 95, 236, 0.4)',
    },
    greys: {
      darkest: grey[800],
      dark: grey[600],
      main: grey[500],
      medium: grey[400],
      mediumLight: grey[300],
      light: grey[200],
      lightest: grey[100],
    },
    easter: {
      pink: '#cc5fed',
      beige: '#e3d8c5',
    },
    mothersDay: {
      red: '#FF1C42',
      pink: '#FF1F91',
      lightPink: '#FF85A6',
    },
    general: {
      body: '#fafafa',
    },
  },
  CSS: {
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerWidth: {
      lg: '75%',
    },
  },
});
export default theme;
