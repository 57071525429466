import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  featureRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.4rem',
    '& svg': {
      color: theme.palette.lifestyle.lightBlue,
      fontSize: '25px',
    },
  },
  featureText: {
    marginBottom: 0,
    paddingLeft: '1rem',
  },
}));

const LifestyleNutritionFeature = ({ feature }) => {
  const classes = useStyles();
  return (
    <div className={classes.featureRow}>
      <CheckIcon fontSize={'inherit'} />
      <p className={classes.featureText}>{feature}</p>
    </div>
  );
};

LifestyleNutritionFeature.propTypes = {
  feature: PropTypes.string,
};

export default LifestyleNutritionFeature;
