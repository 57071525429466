import axiosWrapper from './axiosWrapper';
import { setAlert } from './alert';
import {
  INIT_AXIOS_REQUEST,
  config,
  GET_LIFESTYLE_MEMBERSHIP_SUCCESS,
  GET_LIFESTYLE_MEMBERSHIP_FAIL,
  CREATE_LIFESTYLE_CANCELLATION_REQUEST,
  CREATE_LIFESTYLE_CANCELLATION_SUCCESS,
  INCREMENT_CANCELLATION_STEP,
  SWITCH_TO_LIFESTYLE_NUTRITION_SUCCESS,
  SWITCH_TO_LIFESTYLE_NUTRITION_FAIL,
  CREATE_LIFESTYLE_CANCELLATION_FAIL,
  UPDATE_CANCELLATION_FAIL,
  GET_LIFESTYLE_MEMBERSHIP_REQUEST,
  DISCOUNT_ON_CANCELLATION_REQUEST,
  DISCOUNT_ON_CANCELLATION_FAIL,
  DISCOUNT_ON_CANCELLATION_SUCCESS,
  UPDATE_CANCELLATION_REQUEST,
  UPDATE_CANCELLATION_SUCCESS,
} from './types';

// GET /api/lifestyle-cancellations/:hashToken
export const getLifestyleMembership = (id) => async (dispatch) => {
  dispatch({ type: GET_LIFESTYLE_MEMBERSHIP_REQUEST });
  try {
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}/api/lifestyle-cancellations/${id}`
    );
    // Add hashToken from url since it is not returned on endpoint:
    res.data.hashToken = id;

    dispatch({ type: GET_LIFESTYLE_MEMBERSHIP_SUCCESS, payload: res.data });
  } catch (err) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'error'));
    } else {
      dispatch(setAlert('Something went wrong', 'error'));
    }
    dispatch({ type: GET_LIFESTYLE_MEMBERSHIP_FAIL });
  }
};

// POST /api/lifestyle-cancellations
export const createLifestyleCancellation = (values) => async (dispatch) => {
  dispatch({ type: CREATE_LIFESTYLE_CANCELLATION_REQUEST });
  try {
    const res = await axiosWrapper.post(
      `${process.env.REACT_APP_API_HOST}/api/lifestyle-cancellations`,
      values,
      config
    );
    dispatch({
      type: CREATE_LIFESTYLE_CANCELLATION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'error'));
    } else {
      dispatch(setAlert('Something went wrong', 'error'));
    }
    dispatch({ type: CREATE_LIFESTYLE_CANCELLATION_FAIL });
  }
};

// PUT /api/lifestyle-cancellations/:membershipCancellationId/nutrition
export const switchToNutrition = (values) => async (dispatch) => {
  dispatch({ type: INIT_AXIOS_REQUEST });
  const { membershipCancellationId } = values;
  try {
    const res = await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST}/api/lifestyle-cancellations/${membershipCancellationId}/nutrition`,
      values,
      config
    );
    dispatch({
      type: SWITCH_TO_LIFESTYLE_NUTRITION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'error'));
    } else {
      dispatch(setAlert('Something went wrong', 'error'));
    }
    dispatch({ type: SWITCH_TO_LIFESTYLE_NUTRITION_FAIL });
  }
};

// PUT /api/lifestyle-cancellations/:membershipCancellationId
export const updateCancellation = (values) => async (dispatch) => {
  dispatch({ type: UPDATE_CANCELLATION_REQUEST });
  const { membershipCancellationId } = values;
  try {
    const res = await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST}/api/lifestyle-cancellations/${membershipCancellationId}`,
      values,
      config
    );
    dispatch({ type: UPDATE_CANCELLATION_SUCCESS, payload: res.data });
  } catch (err) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'error'));
    } else {
      dispatch(setAlert('Something went wrong', 'error'));
    }
    dispatch({ type: UPDATE_CANCELLATION_FAIL });
  }
};

export const discountOnCancellation = (values, id) => async (dispatch) => {
  try {
    dispatch({ type: DISCOUNT_ON_CANCELLATION_REQUEST });
    const res = await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST}/api/lifestyle-cancellations/${id}/discount`,
      values,
      config
    );
    dispatch({ type: DISCOUNT_ON_CANCELLATION_SUCCESS, payload: res.data });
  } catch (err) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, 'error'));
    } else {
      dispatch(setAlert('Something went wrong', 'error'));
    }
    dispatch({ type: DISCOUNT_ON_CANCELLATION_FAIL });
  }
};

export const progressStep = () => async (dispatch) => {
  dispatch({ type: INCREMENT_CANCELLATION_STEP });
};
