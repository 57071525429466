import React from 'react';
import { makeStyles } from '@material-ui/core';

import ContentContainer from '../shared/ContentContainer';
import MemberDetails from './components/MemberDetails';
import CancellationFormWrapper from './components/CancellationFormWrapper';
import LoadingSpinner from '../shared/LoadingSpinner';

import lifestyleLogo from '../../assets/images/lifestyle-cancellation/12WBTLifestyleLogo.png';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    fontSize: '1.05rem',
  },
  logo: {
    margin: '3rem auto',
    width: '14rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '85%',
    margin: 'auto',
  },
}));

const Index = () => {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.lifestyleCancellation);
  return (
    <ContentContainer>
      <div className={classes.container}>
        <img src={lifestyleLogo} alt='12WBT Logo' className={classes.logo} />
        <div className={classes.content}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <MemberDetails />
              <CancellationFormWrapper />
            </>
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default Index;
