import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import ScrollToTop from './helpers/ScrollToTop';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import useGTM from '@elgorditosalsero/react-gtm-hook';
import Alert from './components/shared/Alert';
import PageContainer from './components/shared/PageContainer';
import HeaderAndSideBar from './components/shared/navigation';
import LifestyleCancellation from './components/lifestyle-cancellation';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import ContentWrapper from './components/shared/ContentWrapper';
// import PageRedirect from './components/shared/navigation/components/PageRedirect';
import BreakpointDebug from './components/shared/BreakpointDebug';

const App = () => {
  const { init } = useGTM();
  useEffect(() => {
    init({ id: process.env.REACT_APP_GTM_ID });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <PageContainer>
          <ContentWrapper>
            <div className='Main'>
              {window.location.hostname === 'localhost' && <BreakpointDebug />}
              <Alert />
              <HeaderAndSideBar />
              <Switch>
                <Route
                  exact
                  path='/lifestyle-cancellation/:id'
                  component={LifestyleCancellation}
                />
                <Route exact path='/:id' component={LifestyleCancellation} />
                <Route path='*' component={LifestyleCancellation} />
              </Switch>
            </div>
          </ContentWrapper>
        </PageContainer>
      </Router>
    </ThemeProvider>
  );
};
export default App;
