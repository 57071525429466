export const NavbarData = {
  HeaderLinks: [
    {
      link: '/programs',
      title: 'Programs',
    },
    {
      link: '/nutrition',
      title: 'Nutrition',
    },
    {
      link: '/fitness',
      title: 'Fitness',
    },
    {
      link: '/mindset',
      title: 'Mindset',
    },
  ],

  DropdownLinks: [
    {
      link: '/blog',
      title: 'Blog',
    },
    {
      link: '/crews',
      title: 'Crews',
    },
    {
      link: '/group-membership',
      title: 'Corporate Groups',
    },
    {
      link: '/faqs',
      title: 'FAQs',
    },
    {
      link: '/success-stories',
      title: 'Success Stories',
    },
  ],
  LifestyleLinks: [
    {
      link: process.env.REACT_APP_GO_LOGIN,
      title: '12WBT Lifestyle Login',
    },
    {
      link: process.env.REACT_APP_VOOME_NUTRITION,
      title: 'Lifestyle Nutrition Login',
    },
  ],
};

export default NavbarData;
