import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

import CustomButton from '../shared/CustomButton';
import ContentContainer from '../shared/ContentContainer';
import CancellationFormSummary from './components/CancellationFormSummary';

import { useSelector, useDispatch } from 'react-redux';
import {
  progressStep,
  updateCancellation,
} from '../../actions/lifestyleCancellation';

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '1.05rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '2rem',
  },
  reminderText: {
    marginTop: '2rem',
  },
  [theme.breakpoints.down('xs')]: {
    reminderText: {
      fontSize: '1rem',
    },
  },
}));

function buttonStyle(theme) {
  return {
    backgroundColor: theme.palette.lifestyle.darkBlue,
    fontSize: '1.5rem',
    mobileFontSize: '1.2rem',
  };
}

const Index = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { lifestyleMember, loading, loadingUpdateCancellation, cancelled } =
    useSelector((state) => state.lifestyleCancellation);

  // Progress to success step once membership cancelled:
  useEffect(() => {
    if (cancelled) dispatch(progressStep());
  }, [cancelled]);

  const submitCancelForm = () => {
    const values = {
      hashToken: lifestyleMember.hashToken,
      email: lifestyleMember.email,
      name: lifestyleMember.name,
      memberId: lifestyleMember.memberId,
      membership: lifestyleMember.membership,
      membershipCancellationId: lifestyleMember._id,
      chargifySubscriptionId: lifestyleMember.chargifySubscriptionId,
    };
    dispatch(updateCancellation(values));
  };

  const submitCancellationHandler = () => {
    if (loading || loadingUpdateCancellation) return;
    submitCancelForm();
  };

  return (
    <ContentContainer>
      <div className={classes.container}>
        <CancellationFormSummary />
        <CustomButton
          customStyles={buttonStyle(theme)}
          onClick={submitCancellationHandler}
          isLoading={loadingUpdateCancellation}
          disabled={loadingUpdateCancellation}
        >
          Submit
        </CustomButton>
        <p className={classes.reminderText}>
          <b>
            &#42;Please note your subscription will remain active until the end
            of your current billing cycle.&nbsp;
          </b>
          Just a reminder as you have been a Lifestyle Member for more than your
          minimum term, your membership is now a week to week subscription that
          can be cancelled at any time.
        </p>
      </div>
    </ContentContainer>
  );
};

export default Index;
