import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minHeight: '92vh',
  },
  [theme.breakpoints.down('md')]: {
    container: {
      minHeight: '80vh',
    },
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      minHeight: '70vh',
    },
  },
}));
const PageContainer = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};

PageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default PageContainer;
