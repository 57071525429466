import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ReactDOM from 'react-dom';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  sideDrawer: {
    position: 'fixed',
    padding: '1rem',
    left: 0,
    top: 0,
    zIndex: 101,
    width: '300px',
    background: 'white',
    boxShadow: '0 2px 8px rgba(0,0,0,0.26)',
    height: '100vh',
  },
  [theme.breakpoints.down('sm')]: {
    sideDrawer: {
      width: '200px',
    },
  },
}));

const SideDrawer = ({ show, children }) => {
  const classes = useStyles();
  const content = (
    <Slide direction='right' in={show} mountOnEnter unmountOnExit>
      <aside className={classes.sideDrawer}>{children}</aside>
    </Slide>
  );
  return ReactDOM.createPortal(content, document.getElementById('drawer-hook'));
};

SideDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default SideDrawer;
