import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import ContentContainer from '../../ContentContainer';

const HeaderNav = () => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <ContentContainer>
        <div className={classes.container}>
          <a href={process.env.REACT_APP_GO_DASHBOARD}>
            Navigate back to 12WBT
          </a>
        </div>
      </ContentContainer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: 'white',
    width: '100%',
    top: 0,
    position: 'fixed',
    zIndex: 98,
  },
  container: {
    padding: '1rem',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  [theme.breakpoints.down('md')]: {
    grid: {
      padding: '0.2rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    grid: {
      padding: '0.2rem',
      paddingTop: '1rem',
      backgroundColor: 'white',
    },
  },
  [theme.breakpoints.down(335)]: {
    logo: {
      maxWidth: '110px',
      marginLeft: 0,
    },
  },
}));

HeaderNav.propTypes = {
  openSideDrawer: PropTypes.func.isRequired,
};

export default HeaderNav;
