import React from 'react';

import LifestyleCancellationHeader from './components/LifestyleCancellationHeader';
import LifestyleNutritionInfo from './components/LifestyleNutritionInfo';
import SwitchToLifestyleNutrition from './components/SwitchToLifestyleNutrition';
import ConfirmLifestyleCancellation from './components/ConfirmLifestyleCancellation';
import LifestyleDiscountSection from './components/LifestyleDiscountSection';

import { Container } from '@material-ui/core';

const Index = () => {
  return (
    <div>
      <LifestyleCancellationHeader />
      <Container maxWidth='lg'>
        <LifestyleDiscountSection />
        <LifestyleNutritionInfo />
        <SwitchToLifestyleNutrition />
        <ConfirmLifestyleCancellation />
      </Container>
    </div>
  );
};

export default Index;
