import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CustomButton from '../../shared/CustomButton';
import lifestyleLogo from '../../../assets/images/lifestyle-cancellation/12WBTLifestyleLogo.png';
import moment from 'moment';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...theme.CSS.center,
    textAlign: 'center',
    padding: '1rem',
    '& img': {
      width: '12rem',
      margin: 'auto',
    },
  },
  sorryMessage: {
    margin: '1.4rem 0',
  },
  terms: {
    margin: '2rem 0',
  },
}));

function buttonStyle(theme) {
  return {
    backgroundColor: theme.palette.lifestyle.darkBlue,
    fontSize: '1.1rem',
    mobileFontSize: '1rem',
    width: '12rem',
    padding: '.7rem',
    margin: '',
  };
}

const SorryToSeeYouGoModalContent = ({ cancelDate, closeModal }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { lifestyleMemberIsAllowed } = useSelector(
    (state) => state.lifestyleCancellation
  );
  const termsLink = lifestyleMemberIsAllowed?.sixMonthLifer
    ? '/terms/6-months-lifestyle'
    : '/terms/12wbt-lifestyle-membership-faqs';
  return (
    <div className={classes.container}>
      <img src={lifestyleLogo} alt='12WBT Lifestyle logo' />
      <span className={classes.sorryMessage}>
        <b>We're sorry to see you go.</b>
      </span>
      <span>
        Your Lifestyle Membership cancellation will be processed immediately,
        however your account will remain active until{' '}
        <b>{moment(cancelDate).format('DD MMMM YYYY')}</b>
      </span>
      <span className={classes.terms}>
        See{' '}
        <Link
          to={termsLink}
          target='_blank'
          rel='noopener noreferrer'
          data-testid='termsLink'
        >
          Terms and conditions
        </Link>
      </span>
      <CustomButton
        customStyles={buttonStyle(theme)}
        onClick={() => {
          closeModal();
        }}
      >
        Close
      </CustomButton>
    </div>
  );
};

SorryToSeeYouGoModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  cancelDate: PropTypes.string,
};

export default SorryToSeeYouGoModalContent;
