import React from 'react';
import { makeStyles, Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cancelRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '1rem 0',
  },
  cancelText: {
    marginBottom: 0,
  },
  checkboxLabel: {
    marginBottom: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  checkbox: {
    color: '#003b70!important',
  },
  labelRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
  },
}));

const CancelPrompt = ({ onChange, intentionToCancel, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.cancelRow}>
      <p className={classes.cancelText}>
        <b>Do you wish to cancel?</b>
      </p>
      <label className={classes.labelRow}>
        <Checkbox
          className={classes.checkbox}
          onClick={(e) => {
            if (!intentionToCancel) onChange(e);
          }}
          checked={intentionToCancel}
          data-cancel-intention={true}
        />
        <p className={classes.checkboxLabel}>Yes</p>
      </label>
      <label className={classes.labelRow}>
        <Checkbox
          className={classes.checkbox}
          onClick={(e) => {
            if (intentionToCancel) onChange(e);
          }}
          checked={!intentionToCancel}
          data-cancel-intention={false}
        />
        <p className={classes.checkboxLabel}>No</p>
      </label>
    </div>
  );
};

export default CancelPrompt;
