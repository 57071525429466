import React, { Fragment } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import Hero from '../../shared/hero';

import backgroundDesktopTop from '../../../assets/images/lifestyle-cancellation/LifestyleCancellationPageDesktopHeader1.jpg';
import backgroundDesktopBottom from '../../../assets/images/lifestyle-cancellation/LifestyleCancellationPageDesktopHeader2.jpg';
import backgroundMobileTop from '../../../assets/images/lifestyle-cancellation/LifestyleCancellationPageMobileHeader1.jpg';
import backgroundMobileBottom from '../../../assets/images/lifestyle-cancellation/LifestyleCancellationPageMobileHeader2.jpg';
import logoLifestyle from '../../../assets/images/lifestyle-cancellation/12WBTLifestyleLogo.png';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.lifestyle.darkBlue,
    fontSize: '2rem',
    fontWeight: 900,
    textAlign: 'center',
    margin: '0rem auto',
    maxWidth: '90%',
  },
  flexContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  lifestyleLogo: {
    height: '4rem',
    margin: '2.3rem auto',
  },
  subHeader: {
    color: theme.palette.lifestyle.darkBlue,
    fontSize: '1.7rem',
    margin: '1rem auto',
    textAlign: 'center',
  },
  [theme.breakpoints.down('md')]: {
    title: {
      fontSize: '1.8rem',
      maxWidth: '70%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      maxWidth: '85%',
    },
    subHeader: {
      fontSize: '1.3rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    lifestyleLogo: {
      height: '2.8rem',
    },
    title: {
      fontSize: '1.5rem',
    },
  },
}));

const LifestyleCancellationHeader = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Hero
        backgroundDesktop={backgroundDesktopTop}
        backgroundMobile={backgroundMobileTop}
        minHeightDesktopAsNumber={600}
      />
      <Grid
        container
        className={classes.wrapper}
        direction='column'
        justify='center'
        alignItems='center'
      >
        <img
          src={logoLifestyle}
          alt='12WBT Lifestyle Logo'
          className={classes.lifestyleLogo}
        />
      </Grid>

      <p className={classes.title}>
        Don't leave us! <br /> Stay connected and on track with a healthy
        Lifestyle.
      </p>
      <p className={classes.subHeader}>We have two options for you...</p>
      <Hero
        backgroundDesktop={backgroundDesktopBottom}
        backgroundMobile={backgroundMobileBottom}
        minHeightDesktopAsNumber={600}
      />
    </Fragment>
  );
};

export default LifestyleCancellationHeader;
