import React from 'react';
import { makeStyles } from '@material-ui/core';
import NavLinks from './NavLinks';

const useStyles = makeStyles((theme) => ({
  navLinksContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const SidebarContent = ({ onNavigate }) => {
  const classes = useStyles();
  return (
    <div className={classes.navLinksContainer}>
      <NavLinks onNavigate={onNavigate} />
    </div>
  );
};

export default SidebarContent;
