import React from 'react';
import { makeStyles } from '@material-ui/core';

import ContentContainer from '../../shared/ContentContainer';
import LifestyleNutritionFeature from './LifestyleNutritionFeature';

const useStyles = makeStyles((theme) => ({
  featureContent: {
    margin: '2rem',
    marginBottom: 0,
    fontSize: '1.05rem',
  },
  nutritionHeader: {
    fontSize: '1.9rem',
    color: theme.palette.lifestyle.nutritionBlue,
    fontWeight: 900,
    margin: '3rem auto',
    textAlign: 'center',
  },
  topText: {
    maxWidth: '80%',
  },
  costText: {
    margin: '2rem auto',
  },
  [theme.breakpoints.down('xs')]: {
    featureContent: {
      fontSize: '1rem',
      margin: '.2rem',
    },
    nutritionHeader: {
      fontSize: '1.6rem',
    },
  },
}));

const lifestyleNutritionFeatures = [
  'Our entire Recipe Index of over 1000 recipes',
  'Our whole library of Ready-made Meal Plans',
  'Meal Plan Builder - build your own meal plan',
  'Weekly subscription that can be cancelled at any time',
];

const LifestyleNutritionInfo = () => {
  const classes = useStyles();
  return (
    <ContentContainer>
      <div className={classes.featureContent}>
        <h2 className={classes.nutritionHeader}>LIFESTYLE NUTRITION</h2>
        <p className={classes.topText}>
          If having access to our Lifestyle Membership doesn&apos;t suit you
          right now, did you know you can continue to get access to our entire
          Nutrition content library?
        </p>
        <br />
        <p>
          Choose our <b>Lifestyle Nutrition membership</b>, which gives you
          access to:
        </p>
        {lifestyleNutritionFeatures.map((feature, index) => {
          return <LifestyleNutritionFeature feature={feature} key={index} />;
        })}
      </div>
    </ContentContainer>
  );
};

export default LifestyleNutritionInfo;
