import React from 'react';
import { makeStyles } from '@material-ui/core';

import LoadingSpinner from '../../shared/LoadingSpinner';

import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('xs')]: {
    memberDetails: {
      fontSize: '1rem',
    },
  },
}));

const MemberDetails = () => {
  const classes = useStyles();
  const { lifestyleMemberIsAllowed } = useSelector(
    (state) => state.lifestyleCancellation
  );
  return (
    <div>
      {lifestyleMemberIsAllowed ? (
        <p className={classes.memberDetails}>
          <b>Name</b> - {lifestyleMemberIsAllowed.name}
          <br />
          <b>Email</b> - {lifestyleMemberIsAllowed.email}
          <br />
          <b>Membership</b> - {lifestyleMemberIsAllowed.membership}
        </p>
      ) : (
        <LoadingSpinner size={50} minHeight={'250px'} />
      )}
    </div>
  );
};

export default MemberDetails;
