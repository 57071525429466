import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom';

import ContentContainer from '../../shared/ContentContainer';
import CustomButton from '../../shared/CustomButton';

import logoLifestyle from '../../../assets/images/lifestyle-cancellation/12WBTLifestyleLogo.png';

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '1.05rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '2rem',
  },
  reminderText: {
    margin: '2rem',
    textAlign: 'center',
    maxWidth: '60%',
  },
  logo: {
    width: '14rem',
    margin: '2rem auto',
  },
  checkCircle: {
    color: theme.palette.lifestyle.success,
    fontSize: '120px',
  },
  [theme.breakpoints.down('sm')]: {
    reminderText: {
      maxWidth: '100%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    reminderText: {
      margin: '0.2rem',
    },
    container: {
      margin: '2rem 0.5rem',
    },
  },
}));

function buttonStyle(theme) {
  return {
    backgroundColor: theme.palette.lifestyle.darkBlue,
    fontSize: '1.5rem',
    width: '40rem',
    mobileFontSize: '1rem',
  };
}

const LifestyleCancellationSuccess = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  return (
    <ContentContainer>
      <div className={classes.container}>
        <img
          src={logoLifestyle}
          alt='12WBT Logo Lifestyle'
          className={classes.logo}
        />
        <CheckCircleIcon fontSize={'inherit'} className={classes.checkCircle} />
        <p className={classes.reminderText}>
          <b>
            Your notice of cancellation has been successfully received. Your
            subscription will remain active until the end of your current
            billing week and no further payments will be debited.&nbsp;
          </b>
        </p>
        <CustomButton
          customStyles={buttonStyle(theme)}
          href={process.env.REACT_APP_GO_DASHBOARD}
        >
          Back to 12WBT Website
        </CustomButton>
      </div>
    </ContentContainer>
  );
};

export default LifestyleCancellationSuccess;
