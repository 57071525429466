import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import { getLifestyleMembership } from '../../actions/lifestyleCancellation';

import LifestyleCancellationReason from '../lifestyle-cancellation-reason';
import LifestyleCancellationNutritionPrompt from '../lifestyle-cancellation-nutrition-prompt';
import LifestyleCancellationConfirmation from '../lifestyle-cancellation-confirmation/';
import LifestyleCancellationSuccess from '../lifestyle-cancellation-confirmation/components/LifestyleCancellationSuccess';
import InvalidMembershipPage from './InvalidMembershipPage';

const Index = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [hasRetrievedMembership, setHasRetrievedMembership] = useState(false);

  const { lifestyleMemberIsAllowed, loading, cancellationStep } = useSelector(
    (state) => state.lifestyleCancellation
  );

  // On page load get lifestyle membership from hashToken in params:
  useEffect(() => {
    if (
      !lifestyleMemberIsAllowed &&
      id !== null &&
      !loading &&
      !hasRetrievedMembership
    ) {
      console.log('isAllowed call occurred');
      dispatch(getLifestyleMembership(id));
      setHasRetrievedMembership(true);
    }
  }, [
    lifestyleMemberIsAllowed,
    dispatch,
    id,
    loading,
    setHasRetrievedMembership,
    hasRetrievedMembership,
  ]);

  const invalidMembership = cancellationStep === undefined && !loading;
  if (lifestyleMemberIsAllowed?.scheduledCancelDate)
    return (
      <InvalidMembershipPage
        message={`This membership is scheduled to cancel on ${moment(
          lifestyleMemberIsAllowed.scheduledCancelDate
        ).format('MMMM Do YYYY')}`}
      />
    );
  return (
    <div>
      {
        {
          0: <LifestyleCancellationReason />,
          1: <LifestyleCancellationNutritionPrompt />,
          2: <LifestyleCancellationConfirmation />,
          3: <LifestyleCancellationSuccess />,
        }[cancellationStep]
      }
      {invalidMembership && <InvalidMembershipPage />}
    </div>
  );
};

export default Index;
