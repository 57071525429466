import axios from 'axios';

axios.defaults.withCredentials = true;
const axiosWrapper = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json',
});

axiosWrapper.defaults.headers = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};

axiosWrapper.interceptors.request.use((request) => {
  // DO intercept with request here
  return request;
});

const onResponseSuccess = (response) => {
  return response;
};

const onResponseFail = (error) => {
  return Promise.reject(error);
};

axiosWrapper.interceptors.response.use(onResponseSuccess, onResponseFail);

export default axiosWrapper;
