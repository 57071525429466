import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

import CustomButton from '../../shared/CustomButton';
import ContentContainer from '../../shared/ContentContainer';
import Modal from '../../shared/Modal';
import SwitchToLifestyleNutritionModalContent from './SwitchToLifestyleNutritionModalContent';

import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '2rem 2rem',
    fontSize: '1.05rem',
  },
  priceText: {
    margin: '2rem 0',
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      margin: '1rem 0.8rem',
    },
  },
}));

function buttonStyle(theme) {
  return {
    backgroundColor: theme.palette.lifestyle.nutritionBlue,
    margin: '1rem auto',
    width: '30rem',
    fontSize: '1.3rem',
    mobileFontSize: '1rem',
    mobileWidth: '100%',
    mobileMaxWidth: '100%',
    mobileMaxHeight: '3.5rem',
    lineHeight: '1.4rem',
    mobileLineHeight: '0.95rem',
  };
}

const SwitchToLifestyleNutrition = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { success } = useSelector((state) => state.lifestyleCancellation);

  // If user dismisses modal and membership has been successfully switched, redirect:
  useEffect(() => {
    if (success && showModal === false) {
      setRedirect(true);
    }
  }, [success, showModal, setRedirect]);

  if (redirect) {
    window.location.href = process.env.REACT_APP_VOOME_NUTRITION;
  }
  return (
    <Fragment>
      <Modal show={showModal} onCancel={() => setShowModal(!showModal)}>
        <SwitchToLifestyleNutritionModalContent />
      </Modal>
      <ContentContainer>
        <div className={classes.container}>
          <div className={classes.priceText}>
            <span>
              <b>All for just $4 per week.</b>
            </span>
          </div>
          <span>Sound good?</span>
          <CustomButton
            customStyles={buttonStyle(theme)}
            onClick={() => setShowModal(true)}
          >
            Switch me over to Lifestyle Nutrition
          </CustomButton>
        </div>
      </ContentContainer>
    </Fragment>
  );
};

export default SwitchToLifestyleNutrition;
