import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CustomButton from '../../shared/CustomButton';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkCircle: {
    color: theme.palette.lifestyle.success,
    fontSize: '80px',
    margin: '1rem auto',
  },
}));

function buttonStyle(theme) {
  return {
    margin: '1rem, auto',
    marginBottom: '0',
    backgroundColor: theme.palette.lifestyle.darkBlue,
    maxHeight: '2.6rem',
    mobileMaxHeight: '2.6rem',
  };
}
const SwitchModalContentSuccess = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.container}>
      <CheckCircleIcon fontSize={'inherit'} className={classes.checkCircle} />
      <p>
        Your Lifestyle Nutrition membership is now active! Log in with your
        existing details to get started.
      </p>
      <p>12WBT Team. Xx</p>
      <CustomButton
        customStyles={buttonStyle(theme)}
        href={process.env.REACT_APP_VOOME_NUTRITION}
      >
        Go to Login
      </CustomButton>
    </div>
  );
};

export default SwitchModalContentSuccess;
