import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';

import CancellationForm from './CancellationForm';
import { validationSchema } from './LifestyleCancellationData';

import { useSelector, useDispatch } from 'react-redux';
import {
  createLifestyleCancellation,
  progressStep,
} from '../../../actions/lifestyleCancellation';
import Modal from '../../shared/Modal';
import SorryToSeeYouGoModalContent from './SorryToSeeYouGoModalContent';
import { updateCancellation } from '../../../actions/lifestyleCancellation';

const CancellationFormWrapper = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const {
    lifestyleMemberIsAllowed,
    success,
    formFinalized,
    lifestyleMember,
    loading,
    cancelled,
    cancelDate,
  } = useSelector((state) => state.lifestyleCancellation);

  const couponCode = '30OFFLIFESTYLE';
  useEffect(() => {
    if (success && formFinalized) {
      // Continue through cancellation steps to offer lifers nutrition or 30% off 6 months:
      // (members already in 6 months lifers should be cancelled immediately and not offered alternatives again):
      if (
        lifestyleMemberIsAllowed?.allowed &&
        !lifestyleMemberIsAllowed?.sixMonthLifer
      ) {
        dispatch(progressStep());
      } else if (lifestyleMember && !loading) {
        // Cancel membership here:
        const values = {
          hashToken: lifestyleMember.hashToken,
          email: lifestyleMember.email,
          name: lifestyleMember.name,
          memberId: lifestyleMember.memberId,
          membership: lifestyleMember.membership,
          membershipCancellationId: lifestyleMember._id,
          chargifySubscriptionId: lifestyleMember.chargifySubscriptionId,
        };
        dispatch(updateCancellation(values));
      }
    }
  }, [success, lifestyleMember]);

  // Listener for successful cancellation:
  useEffect(() => {
    if (cancelled) setShowModal(true);
  }, [cancelled]);

  const modalCloseHandler = () => {
    setShowModal(false);
    // Navigate away:
    window.location.href = `${process.env.REACT_APP_12WBT}my-account`;
  };

  return (
    <Formik
      initialValues={{
        intentionToCancel: false,
        reasonForCancellation: '',
        reasonId: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const valuesToSend = {
          hashToken: lifestyleMemberIsAllowed.hashToken,
          email: lifestyleMemberIsAllowed.email,
          name: lifestyleMemberIsAllowed.name,
          memberId: lifestyleMemberIsAllowed.memberId,
          membershipName: lifestyleMemberIsAllowed.membershipName,
          membership: lifestyleMemberIsAllowed.membership,
          chargifySubscriptionId:
            lifestyleMemberIsAllowed.chargifySubscriptionId,
          reason: values.reasonForCancellation,
          reasonId: values.reasonId,
        };
        dispatch(createLifestyleCancellation(valuesToSend));
      }}
    >
      {({ submitForm, setFieldValue, errors, values }) => (
        <>
          <CancellationForm
            values={values}
            setFieldValue={setFieldValue}
            submitForm={() => {
              submitForm();
            }}
            errors={errors}
          />
          <Modal show={showModal} onCancel={modalCloseHandler}>
            <SorryToSeeYouGoModalContent
              closeModal={modalCloseHandler}
              cancelDate={cancelDate}
            />
          </Modal>
        </>
      )}
    </Formik>
  );
};

export default CancellationFormWrapper;
