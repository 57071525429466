import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import UseWindowDimensions from '../../hooks/UseWindowDimensions';

const checkBreakpoint = (width, breakpointArray, currentBreakpoint, i) => {
  if (width > breakpointArray[i][1]) currentBreakpoint = breakpointArray[i][0];
  return currentBreakpoint;
};

const BreakpointDebug = () => {
  const { width } = UseWindowDimensions();
  const theme = useTheme();
  const breakpointArray = Object.entries(theme.breakpoints.values);
  let currentBreakpoint = null;
  breakpointArray.forEach((breakpoint, i) => {
    currentBreakpoint = checkBreakpoint(
      width,
      breakpointArray,
      currentBreakpoint,
      i
    );
  });
  const classes = useStyles();
  return <div className={classes.breakpointDiv}>{currentBreakpoint}</div>;
};

const useStyles = makeStyles((theme) => ({
  breakpointDiv: {
    position: 'fixed',
    top: 0,
    left: 0,
    border: '1px solid white',
    backgroundColor: 'black',
    color: 'white',
    padding: '.4rem',
    zIndex: 9999999,
  },
}));

export default BreakpointDebug;
