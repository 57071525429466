import { combineReducers } from 'redux';
import alert from './alert';
import lifestyleCancellation, {
  switchtoDiscountReducer,
} from './lifestyleCancellation';

const appReducer = combineReducers({
  alert,
  lifestyleCancellation,
  discount: switchtoDiscountReducer,
});

// Reducers return the initial state when they are called with undefined as first argument:
// When RESET_STORE is called, all reducers are initialized anew:
const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
