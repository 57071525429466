import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    color: (props) => props.customStyles.spinnerColor,
  },
  link: {
    margin: (props) => props.customStyles.margin,
    width: (props) => props.customStyles.width,
    minWidth: (props) => props.customStyles.minWidth,
    marginBottom: (props) => props.customStyles.marginBottom,
    borderRadius: (props) => props.customStyles.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  title: {
    textTransform: 'none',
    margin: 0,
    textDecoration: 'none',
    fontSize: (props) => props.customStyles.fontSize,
    fontWeight: (props) => props.customStyles.fontWeight,
    color: (props) => props.customStyles.color,
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  button: {
    backgroundColor: (props) =>
      props.disabled ? grey[500] : props.customStyles.backgroundColor,
    borderRadius: (props) => props.customStyles.borderRadius,
    border: (props) => props.customStyles.border,
    padding: (props) => props.customStyles.padding,
    lineHeight: (props) => props.customStyles.lineHeight,
    minHeight: (props) => props.customStyles.minHeight,
    maxHeight: (props) => props.customStyles.maxHeight,
    width: '100%',
    '&:hover': {
      backgroundColor: (props) =>
        props.filled ? props.customStyles.hoverColor : '',
      borderColor: (props) => (props.filled ? '' : grey[500]),
      '& p': {
        color: (props) => (props.filled ? props.customStyles.color : grey[500]),
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    link: {
      width: (props) => props.customStyles.mobileWidth,
      maxWidth: (props) => props.customStyles.mobileMaxWidth,
    },
    button: {
      padding: '0rem',
      maxHeight: (props) => props.customStyles.mobileMaxHeight,
      lineHeight: (props) => props.customStyles.mobileLineHeight,
    },
    title: {
      fontSize: (props) => props.customStyles.mobileFontSize,
      width: (props) => props.customStyles.mobileMaxWidth,
    },
  },
}));

const title = (isLoading, spinnerClass, titleClass, children) => {
  return isLoading ? (
    <CircularProgress color='inherit' className={spinnerClass} size={20} />
  ) : (
    <p className={titleClass}>{children}</p>
  );
};

export const CustomButton = ({
  customStyles,
  styles,
  to,
  href,
  onClick,
  isLoading,
  filled,
  children,
  disabled,
  inheritedClass,
}) => {
  customStyles = { ...defaultProps, ...customStyles };
  const isDisabled = disabled || isLoading;
  const classes = useStyles({
    customStyles,
    filled,
    disabled: isDisabled,
  });

  const buttonClass = inheritedClass ? inheritedClass : classes.button;
  return (
    <div className={classes.buttonContainer}>
      {to ? (
        <Link
          to={isDisabled ? '#' : to}
          className={classes.link}
          onClick={onClick}
        >
          <Button
            className={buttonClass}
            style={styles}
            disabled={isDisabled}
            data-testid={children}
          >
            {title(isLoading, classes.spinner, classes.title, children)}
          </Button>
        </Link>
      ) : (
        <a href={href} className={classes.link} onClick={onClick}>
          <Button
            className={buttonClass}
            style={styles}
            disabled={isDisabled}
            data-testid={children}
          >
            {title(isLoading, classes.spinner, classes.title, children)}
          </Button>
        </a>
      )}
    </div>
  );
};

CustomButton.propTypes = {
  customStyles: PropTypes.shape({
    margin: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    border: PropTypes.string,
    borderColor: PropTypes.string,
    borderRadius: PropTypes.string,
    hoverColor: PropTypes.string,
    width: PropTypes.string,
    minWidth: PropTypes.string,
    padding: PropTypes.string,
    minHeight: PropTypes.string,
    maxHeight: PropTypes.string,
    marginBottom: PropTypes.string,
    mobileFontSize: PropTypes.string,
    mobileMaxWidth: PropTypes.string,
    mobileWidth: PropTypes.string,
    mobileMaxHeight: PropTypes.string,
    mobileLineHeight: PropTypes.string,
    spinnerColor: PropTypes.string,
  }),
  styles: PropTypes.object,
  href: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  filled: PropTypes.bool,
  disabled: PropTypes.bool,
  inheritedClass: PropTypes.string,
};

CustomButton.defaultProps = {
  filled: true,
  disabled: false,
};

const defaultProps = {
  margin: '2rem auto',
  fontSize: '1rem',
  fontWeight: 900,
  backgroundColor: '#4ca5ff',
  color: 'white',
  borderRadius: '30px',
  width: '310px',
  mobileMaxWidth: '90%',
  hoverColor: grey[500],
  mobileMaxHeight: 'inherit',
  mobileWidth: '310px',
  mobileLineHeight: '1.2rem',
  lineHeight: '1.2rem',
  padding: '1rem',
  isLoading: false,
  spinnerColor: 'white',
};

export default CustomButton;
