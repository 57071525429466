import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import logo from '../../assets/images/logo/12wbtlogo.png';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '50vh',
    ...theme.CSS.center,
  },
  messageDiv: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      margin: '1rem auto',
      width: '10rem',
    },
  },
}));

const InvalidMembershipPage = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.messageDiv}>
        <img src={logo} />
        <span>{message}</span>
      </div>
    </div>
  );
};

InvalidMembershipPage.defaultProps = {
  message: 'Could not find an eligible Lifestyle membership to cancel',
};

InvalidMembershipPage.propTypes = {
  message: PropTypes.string,
};

export default InvalidMembershipPage;
