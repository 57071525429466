import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import lifestyleLogo from '../../../assets/images/lifestyle-cancellation/12WBTLifestyleLogo.png';
import CustomButton from '../../shared/CustomButton';

import { useDispatch, useSelector } from 'react-redux';
import DiscountAppliedSuccessModal from './DiscountAppliedSuccessModalContent';
import { discountOnCancellation } from '../../../actions/lifestyleCancellation';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    ...theme.CSS.center,
    flexDirection: 'column',
    textAlign: 'center',
    padding: '2rem',
    justifyContent: 'space-around',
  },
  topText: {
    margin: '1rem auto',
  },
  logo: {
    width: '7rem',
  },
}));

function buttonStyle(theme) {
  return {
    mobileWidth: '100%',
    margin: '0.2rem auto',
    backgroundColor: theme.palette.lifestyle.darkBlue,
    fontSize: '1.1rem',
    lineHeight: '1.1rem',
    mobileFontSize: '.85rem',
  };
}

const ConfirmUpfrontPaymentModalContent = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { lifestyleMember } = useSelector(
    (state) => state.lifestyleCancellation
  );
  const { loading, success } = useSelector((state) => state.discount);

  const confirmDiscountedUpfrontPaymentHandler = () => {
    if (loading) return;
    const values = {
      hashToken: lifestyleMember.hashToken,
      email: lifestyleMember.email,
      name: lifestyleMember.name,
      memberId: lifestyleMember.memberId,
      membershipName: lifestyleMember.membershipName,
      membership: lifestyleMember.membership,
      chargifySubscriptionId: lifestyleMember.chargifySubscriptionId,
      reason: lifestyleMember.reason,
      reasonId: lifestyleMember.reasonId,
    };
    dispatch(discountOnCancellation(values, lifestyleMember._id));
  };

  return success ? (
    <DiscountAppliedSuccessModal />
  ) : (
    <div className={classes.modalContainer}>
      <img
        src={lifestyleLogo}
        className={classes.logo}
        alt='12WBT Lifestyle logo'
      />
      <p className={classes.topText}>
        Great! We’ll adjust your Lifestyle Membership payment to one upfront
        payment of $181 to cover you for the next 6 months. Keep a look out in
        your email inbox for your transaction confirmation. <br />
        <br />
        Sign in with your current log in details to continue your access
        straight away.
      </p>
      <p>12WBT Team. Xx</p>
      <CustomButton
        customStyles={buttonStyle(theme)}
        onClick={confirmDiscountedUpfrontPaymentHandler}
        isLoading={loading}
        disabled={loading}
      >
        Confirm and Proceed
      </CustomButton>
    </div>
  );
};

export default ConfirmUpfrontPaymentModalContent;
