import React from 'react';
import { makeStyles } from '@material-ui/core';

import SwitchModalContentConfirm from './SwitchModalContentConfirm';
import SwitchModalContentSuccess from './SwitchModalContentSuccess';

import logoLifestyle from '../../../assets/images/lifestyle-cancellation/12WBTLifestyleLogoColor.png';

import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.4rem',
    textAlign: 'left!important',
  },
  logo: {
    height: '2.5rem',
    margin: '0 auto',
    marginBottom: '1rem',
  },
  [theme.breakpoints.down('xs')]: {
    modalContent: {
      margin: '0.6rem',
    },
    logo: {
      height: '1.82rem',
      margin: '1rem auto',
    },
  },
}));

const SwitchToLifestyleNutritionModalContent = () => {
  const classes = useStyles();
  const { success } = useSelector((state) => state.lifestyleCancellation);

  return (
    <div className={classes.modalContent}>
      <img
        src={logoLifestyle}
        alt='12WBT Lifestyle Logo'
        className={classes.logo}
      />
      {!success ? <SwitchModalContentConfirm /> : <SwitchModalContentSuccess />}
    </div>
  );
};

export default SwitchToLifestyleNutritionModalContent;
