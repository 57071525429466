import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import logoLifestyle from '../../../assets/images/lifestyle-cancellation/12WBTLifestyleLogo.png';

import { useSelector } from 'react-redux';
import LoadingSpinner from '../../shared/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '14rem',
    margin: '2rem auto',
  },
  check: {
    color: theme.palette.lifestyle.lightBlue,
    fontSize: '25px',
  },
  [theme.breakpoints.down('xs')]: {
    memberDetails: {
      fontSize: '0.9rem',
    },
  },
}));

const CancellationFormSummary = () => {
  const classes = useStyles();
  const { lifestyleMember } = useSelector(
    (state) => state.lifestyleCancellation
  );
  if (lifestyleMember) {
    return (
      <Fragment>
        <img
          src={logoLifestyle}
          alt='12WBT Logo Lifestyle'
          className={classes.logo}
        />
        <p className={classes.memberDetails}>
          <b>Name</b> - {lifestyleMember.name}
          <br />
          <b>Email</b> - {lifestyleMember.email}
          <br />
          <b>Membership</b> - {lifestyleMember.membershipName}
          <br />
          <br />
          <b>
            <CheckIcon fontSize={'inherit'} className={classes.check} />
            &nbsp;&nbsp;I agree to cancel my lifestyle membership
          </b>
          <br />
          <br />
          <b>Reason for cancellation</b> - {lifestyleMember.reason}
        </p>
      </Fragment>
    );
  }
  return <LoadingSpinner size={50} minHeight={'250px'} />;
};

export default CancellationFormSummary;
