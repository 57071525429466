import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
}));
const ContentContainer = ({ children, maxWidth }) => {
  const classes = useStyles();
  return (
    <Container maxWidth={maxWidth} className={classes.container}>
      {children}
    </Container>
  );
};

ContentContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  maxWidth: PropTypes.string,
};

ContentContainer.defaultProps = {
  maxWidth: 'xl',
};

export default ContentContainer;
