import React, { forwardRef } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import NavbarData from './NavbarData';

const useStyles = makeStyles((theme) => ({
  link: {
    color: 'black',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.lifestyle.lightBlue,
    },
    textAlign: 'center',
  },
  menuItem: {
    fontSize: '0.9rem',
  },
}));

const SupportMenuContent = ({ handleClose, onNavigate }, ref) => {
  const classes = useStyles();
  return (
    <div>
      {NavbarData.DropdownLinks.map((item, index) => {
        return (
          <Link
            className={classes.link}
            to={item.link}
            key={index}
            onClick={() => handleClose()}
          >
            <MenuItem className={classes.menuItem} onClick={onNavigate}>
              {item.title}
            </MenuItem>
          </Link>
        );
      })}
    </div>
  );
};

export default forwardRef(SupportMenuContent);
