import React, { useState } from 'react';
import { Form } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core';

import CustomButton from '../../shared/CustomButton';
import Error from './Error';
import CancelPrompt from './CancelPrompt';
import CancellationReason from './CancellationReason';
import LoadingSpinner from '../../shared/LoadingSpinner';

import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  [theme.breakpoints.down('xs')]: {
    form: {
      fontSize: '1rem',
    },
  },
}));

function buttonStyle(theme) {
  return {
    backgroundColor: theme.palette.lifestyle.darkBlue,
    fontSize: '1.5rem',
    mobileFontSize: '1.2rem',
  };
}

// Assign a boolean value to formik form value intentionToCancel:
const setIntentionToCancel = (e, setFieldValue) => {
  let cancelIntention = false;
  const cancelIntentionString = e.currentTarget.getAttribute(
    'data-cancel-intention'
  );
  if (cancelIntentionString === 'true') {
    cancelIntention = true;
  } else {
    cancelIntention = false;
  }
  setFieldValue('intentionToCancel', cancelIntention);
};

// Check the data attribute value for the checkbox clicked on & set the relevant field:
const setCancellationReason = (e, setFieldValue) => {
  const cancelReason = e.currentTarget.getAttribute('data-cancel-reason');
  const cancelReasonId = e.currentTarget.getAttribute('data-reason-id');
  setFieldValue('reasonForCancellation', cancelReason);
  setFieldValue('reasonId', cancelReasonId);
};

// Handle reason for cancellation checkbox clicks:
const setOtherReason = (e, setFieldValue) => {
  const otherReasonId = e.currentTarget.getAttribute('data-reason-id');
  setFieldValue('reasonForCancellation', e.currentTarget.value);
  setFieldValue('reasonId', otherReasonId);
};

const CancellationForm = ({ values, setFieldValue, submitForm, errors }) => {
  const [submissionAttempt, setSubmissionAttempt] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const { loading, loadingUpdateCancellation, lifestyleMemberIsAllowed } =
    useSelector((state) => state.lifestyleCancellation);
  const actionLoading = loading || loadingUpdateCancellation;
  return (
    <Form className={classes.form}>
      {actionLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <CancelPrompt
            intentionToCancel={values.intentionToCancel}
            onChange={(e) => {
              setIntentionToCancel(e, setFieldValue);
            }}
          />
          <CancellationReason
            cancelReason={values.reasonForCancellation}
            onChange={(e) => {
              setCancellationReason(e, setFieldValue);
            }}
            onTextAreaChange={(e) => {
              setOtherReason(e, setFieldValue);
            }}
          />
        </>
      )}

      {errors.intentionToCancel && submissionAttempt && (
        <Error error={errors.intentionToCancel} />
      )}
      {errors.reasonForCancellation && submissionAttempt && (
        <Error error={errors.reasonForCancellation} />
      )}
      <CustomButton
        customStyles={buttonStyle(theme)}
        onClick={() => {
          setSubmissionAttempt(true);
          submitForm();
        }}
        isLoading={actionLoading}
        disabled={actionLoading}
      >
        {lifestyleMemberIsAllowed?.allowed &&
        !lifestyleMemberIsAllowed?.sixMonthLifer
          ? 'Continue'
          : 'Cancel'}
      </CustomButton>
    </Form>
  );
};

export default CancellationForm;
