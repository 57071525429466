import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ContentContainer from '../ContentContainer';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: (props) => `url(${props.backgroundDesktop})`,
    minHeight: (props) => `${props.minHeightDesktopAsNumber}px`,
    width: '100%',
    backgroundColor: '#cecece',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: (props) => props.backgroundPositionY,
    color: '#fff',
    fontSize: '50px',
    paddingLeft: (props) => props.padding,
    paddingRight: (props) => props.padding,
    boxShadow: (props) => props.overlay,
  },
  bannerOverlay: {
    width: '100%',
    bottom: 0,
    height: (props) => `${props.minHeightDesktopAsNumber * 0.3}px`,
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    wrapper: {
      minHeight: (props) => `${props.minHeightTabletAsNumber}px`,
    },
    bannerOverlay: {
      bottom: 0,
      height: (props) => `${props.minHeightTabletAsNumber * 0.3}px`,
    },
  },
  [theme.breakpoints.down('xs')]: {
    wrapper: {
      backgroundImage: (props) => `url(${props.backgroundMobile})`,
      minHeight: (props) => `${props.minHeightMobileAsNumber}px`,
      maxHeight: (props) => props.mobileMaxHeight,
      backgroundPositionY: (props) => props.smBackgroundPositionY,
    },
    bannerOverlay: {
      bottom: 0,
      height: (props) => `${props.minHeightMobileAsNumber * 0.3}px`,
    },
  },
}));

export const Hero = ({
  backgroundDesktop,
  backgroundMobile,
  backgroundPositionY,
  children,
  padding,
  withOverlay,
  overlayStrengthAsDecimal,
  withBannerOverlay,
  minHeightDesktopAsNumber,
  minHeightTabletAsNumber,
  minHeightMobileAsNumber,
  mobileMaxHeight,
}) => {
  const overlay =
    withOverlay &&
    `inset 20000px 0 0 0 rgba(0, 0, 0, ${overlayStrengthAsDecimal})`;
  const classes = useStyles({
    backgroundDesktop,
    backgroundMobile,
    backgroundPositionY,
    padding,
    overlay,
    minHeightDesktopAsNumber,
    minHeightTabletAsNumber,
    minHeightMobileAsNumber,
    mobileMaxHeight,
  });

  const renderChildren = () => {
    if (withBannerOverlay) {
      return (
        <div className={classes.bannerOverlay}>
          <ContentContainer>
            {children ? children : <div></div>}
          </ContentContainer>
        </div>
      );
    }
    return (
      <ContentContainer>{children ? children : <div></div>}</ContentContainer>
    );
  };

  return (
    <Grid
      container
      className={classes.wrapper}
      direction='column'
      justify={withBannerOverlay ? 'flex-end' : 'center'}
      alignItems='center'
    >
      {renderChildren()}
    </Grid>
  );
};

Hero.propTypes = {
  backgroundDesktop: PropTypes.string.isRequired,
  backgroundMobile: PropTypes.string.isRequired,
  minHeightDesktopAsNumber: PropTypes.number,
  minHeightTabletAsNumber: PropTypes.number,
  minHeightMobileAsNumber: PropTypes.number,
  smBackgroundPositionY: PropTypes.string,
  backgroundPositionY: PropTypes.string,
  mobileMaxHeight: PropTypes.string,
  withOverlay: PropTypes.bool,
  overlayStrengthAsDecimal: PropTypes.string,
  withBannerOverlay: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

Hero.defaultProps = {
  padding: '0px',
  minHeightDesktopAsNumber: 500,
  minHeightTabletAsNumber: 400,
  minHeightMobileAsNumber: 220,
  withOverlay: false,
  overlayStrengthAsDecimal: '0.3',
  withBannerOverlay: false,
  mobileMaxHeight: '',
};

export default Hero;
