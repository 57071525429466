import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: (props) => props.minHeight,
  },
  spinner: {
    color: theme.palette.lifestyle.darkBlue,
  },
}));
const LoadingSpinner = ({ size, minHeight }) => {
  const classes = useStyles({ minHeight });
  return (
    <div className={classes.spinnerContainer}>
      <CircularProgress className={classes.spinner} size={size} />
    </div>
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LoadingSpinner.defaultProps = {
  size: 100,
  minHeight: '500px',
};

export default LoadingSpinner;
