import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import CustomButton from '../../shared/CustomButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
  },
  checkCircle: {
    color: theme.palette.lifestyle.success,
    fontSize: '80px',
    margin: '1rem auto',
  },
}));

function buttonStyle(theme) {
  return {
    mobileWidth: '100%',
    margin: '0.2rem auto',
    backgroundColor: theme.palette.lifestyle.darkBlue,
    fontSize: '1.1rem',
    lineHeight: '1.1rem',
    mobileFontSize: '.85rem',
  };
}

const DiscountAppliedSuccessModalContent = () => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CheckCircleIcon fontSize={'inherit'} className={classes.checkCircle} />
      <p>
        Your Lifestyle membership discount is now active!
        <br /> Log in with your existing details to continue.
      </p>
      <p>12WBT Team. Xx</p>
      <CustomButton
        customStyles={buttonStyle(theme)}
        href={process.env.REACT_APP_GO_LOGIN}
      >
        Go to Login
      </CustomButton>
    </div>
  );
};

export default DiscountAppliedSuccessModalContent;
