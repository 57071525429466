import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import NavLink from './NavLink';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NavbarData from './NavbarData';

import SupportMenuContent from './SupportMenuContent';
import LifestyleMenuContent from './LifestyleMenuContent';

const useStyles = makeStyles((theme) => ({
  navLinks: {
    display: 'flex',
    position: 'relative',
    '& a': {
      marginLeft: '3rem',
    },
    '& :first-child': {
      marginLeft: '0px',
    },
    '& #extratag': {
      marginLeft: '3rem',
    },
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.lifestyle.lightBlue,
    },
    textAlign: 'center',
  },
  menu: {
    padding: '0!important',
    marginTop: '40px',
  },
  [theme.breakpoints.down('lg')]: {
    navLinks: {
      padding: '1rem',
      '& a': {
        marginLeft: '1.9rem',
      },
      '& #extratag': {
        marginLeft: '1.9rem',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    navLinks: {
      paddingTop: '5rem',
      flexDirection: 'column',
      '& a': {
        margin: '0.8rem',
        fontSize: '1.1rem',
      },
      '& :first-child': {
        marginLeft: '0.8rem',
      },
      '& #extratag': {
        margin: '0.8rem',
        fontSize: '1.1rem',
        display: 'flex',
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    navLinks: {
      padding: '0',
      '& a': {
        margin: '0.9rem',
      },
      '& #extratag': {
        margin: '0.9rem',
      },
      paddingTop: '5rem',
    },
  },
}));

const ACTIVEMENU = {
  SUPPORT: 'Support',
  LIFESTYLE: 'Lifestyle',
};

const NavLinks = ({ onNavigate }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);

  const handleDropDownClick = (event, menuItem) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(menuItem);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveMenu(null);
  };

  const menuContent = () => {
    if (activeMenu === ACTIVEMENU.SUPPORT) {
      return (
        <SupportMenuContent handleClose={handleClose} onNavigate={onNavigate} />
      );
    } else if (activeMenu === ACTIVEMENU.LIFESTYLE) {
      return (
        <LifestyleMenuContent
          handleClose={handleClose}
          onNavigate={onNavigate}
        />
      );
    }
  };

  return (
    <div className={classes.navLinks}>
      {NavbarData.HeaderLinks.map((navlink, index) => {
        return (
          <NavLink
            title={navlink.title}
            link={navlink.link}
            key={index}
            onClick={onNavigate}
          />
        );
      })}
      <div
        className={classes.link}
        id={'extratag'}
        onClick={(e) => {
          handleDropDownClick(e, ACTIVEMENU.SUPPORT);
        }}
      >
        Support
        <ArrowDropDownIcon />
      </div>
      <div
        className={classes.link}
        id={'extratag'}
        onClick={(e) => {
          handleDropDownClick(e, ACTIVEMENU.LIFESTYLE);
        }}
      >
        Lifestyle
        <ArrowDropDownIcon />
      </div>
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuContent()}
      </Menu>
    </div>
  );
};

NavLinks.propTypes = {
  onNavigate: PropTypes.func,
};

export default NavLinks;
