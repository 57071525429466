import React from 'react';
import { makeStyles, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  labelRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
  },
  checkboxLabel: {
    marginBottom: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  checkbox: {
    color: '#003b70!important',
  },
  [theme.breakpoints.down('xs')]: {
    checkboxLabel: {
      fontSize: '0.9rem',
    },
    labelRow: {
      margin: '0.2rem 0',
    },
  },
}));

const CancellationCheckbox = ({ checkboxReason, cancelReason, reasonId, onChange }) => {
  const classes = useStyles();
  return (
    <label className={classes.labelRow}>
      <Checkbox
        className={classes.checkbox}
        onClick={(e) => {
          if (checkboxReason !== cancelReason) onChange(e);
        }}
        checked={checkboxReason === cancelReason}
        data-cancel-reason={checkboxReason}
        data-reason-id={reasonId}
      />
      <p className={classes.checkboxLabel}>{checkboxReason}</p>
    </label>
  );
};

CancellationCheckbox.propTypes = {
  checkboxReason: PropTypes.string,
  cancelReason: PropTypes.string,
  reasonId: PropTypes.string,
  onChange: PropTypes.func,
};

export default CancellationCheckbox;
