import {
  INIT_AXIOS_REQUEST,
  INCREMENT_CANCELLATION_STEP,
  CREATE_LIFESTYLE_CANCELLATION_SUCCESS,
  GET_LIFESTYLE_MEMBERSHIP_SUCCESS,
  GET_LIFESTYLE_MEMBERSHIP_FAIL,
  SWITCH_TO_LIFESTYLE_NUTRITION_SUCCESS,
  UPDATE_CANCELLATION_REQUEST,
  UPDATE_CANCELLATION_SUCCESS,
  UPDATE_CANCELLATION_FAIL,
  CREATE_LIFESTYLE_CANCELLATION_FAIL,
  SWITCH_TO_LIFESTYLE_NUTRITION_FAIL,
  GET_LIFESTYLE_MEMBERSHIP_REQUEST,
  DISCOUNT_ON_CANCELLATION_SUCCESS,
  DISCOUNT_ON_CANCELLATION_REQUEST,
  DISCOUNT_ON_CANCELLATION_FAIL,
  CREATE_LIFESTYLE_CANCELLATION_REQUEST,
} from '../actions/types';

const initialState = {
  lifestyleMember: null,
  loading: false,
  success: false,
  formFinalized: false,
  cancellationStep: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_AXIOS_REQUEST:
    case CREATE_LIFESTYLE_CANCELLATION_REQUEST:
      return { ...state, loading: true, success: false };
    case UPDATE_CANCELLATION_REQUEST:
      return { ...state,  loadingUpdateCancellation: true}
    case GET_LIFESTYLE_MEMBERSHIP_REQUEST:
      return { ...state, loading: true };
    case GET_LIFESTYLE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        lifestyleMemberIsAllowed: payload,
        loading: false,
        success: true,
      };
    case CREATE_LIFESTYLE_CANCELLATION_SUCCESS: {
      return {
        ...state,
        lifestyleMember: payload,
        loading: false,
        success: true,
        formFinalized: true,
      };
    }
    case SWITCH_TO_LIFESTYLE_NUTRITION_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
      };
    }
    case INCREMENT_CANCELLATION_STEP: {
      let newCancellationStep = state.cancellationStep + 1;
      let newState = {
        ...state,
        cancellationStep: newCancellationStep,
        success: false,
      };
      return newState;
    }
    case UPDATE_CANCELLATION_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        cancelled: true,
        cancelDate: payload?.cancelDate,
        loadingUpdateCancellation: false
      };
    }
    case GET_LIFESTYLE_MEMBERSHIP_FAIL: {
      return {
        invalidMember: true,
        success: false,
        loading: false,
      };
    }
    case UPDATE_CANCELLATION_FAIL:
    case CREATE_LIFESTYLE_CANCELLATION_FAIL:
    case SWITCH_TO_LIFESTYLE_NUTRITION_FAIL: {
      return {
        ...state,
        success: false,
        loading: false,
        loadingUpdateCancellation: false
      };
    }
    default:
      return state;
  }
}

export const switchtoDiscountReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case DISCOUNT_ON_CANCELLATION_REQUEST: {
      return { loading: true };
    }
    case DISCOUNT_ON_CANCELLATION_SUCCESS: {
      return {
        loading: false,
        success: payload,
      };
    }
    case DISCOUNT_ON_CANCELLATION_FAIL: {
      return { loading: false, success: false };
    }
    default:
      return state;
  }
};
