import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, TextareaAutosize } from '@material-ui/core';
import PropTypes from 'prop-types';

import CancellationCheckbox from './CancellationCheckbox';
import LoadingSpinner from '../../shared/LoadingSpinner';

import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem 0',
  },
  reasonTitle: {
    marginBottom: '0.4rem',
  },
  textArea: {
    marginTop: '0.8rem',
  },
}));

const CancellationReason = ({ cancelReason, onChange, onTextAreaChange }) => {
  const classes = useStyles();
  const textAreaRef = useRef();
  const [reasons, setReasons] = useState(null);
  const [otherReasonId, setOtherReasonId] = useState();

  const { lifestyleMemberIsAllowed } = useSelector(
    (state) => state.lifestyleCancellation
  );

  // Clear TextArea if another checkbox is selected:
  useEffect(() => {
    if (cancelReason !== textAreaRef.current.value) {
      textAreaRef.current.value = '';
    }
  }, [cancelReason, textAreaRef]);

  // Store reasons from redux in local state to prevent error on submission when redux is updated:
  useEffect(() => {
    if (!reasons && lifestyleMemberIsAllowed?.reasons) {
      // Filter array to remove 'Other' as a checkbox and save 'Other's reason id:
      const reasonsArray = lifestyleMemberIsAllowed.reasons.filter((obj) => {
        if (obj.reason === 'Other') setOtherReasonId(obj._id);
        return obj.reason !== 'Other';
      });
      setReasons(reasonsArray);
    }
  }, [reasons, setReasons, lifestyleMemberIsAllowed]);

  return (
    <div className={classes.container}>
      <p className={classes.reasonTitle}>
        <b>Reason for cancellation:</b>
      </p>
      {reasons ? (
        reasons.map((reasonObj, index) => {
          return (
            <CancellationCheckbox
              checkboxReason={reasonObj.reason}
              reasonId={reasonObj._id}
              cancelReason={cancelReason}
              key={index}
              onChange={onChange}
            />
          );
        })
      ) : (
        <LoadingSpinner size={50} minHeight={'250px'} />
      )}
      <TextareaAutosize
        ariea-label='Other - please specify.'
        rowsMin={6}
        placeholder={'Other - please specify.'}
        onChange={(e) => onTextAreaChange(e)}
        ref={textAreaRef}
        className={classes.textArea}
        data-reason-id={otherReasonId}
      />
    </div>
  );
};

CancellationReason.propTypes = {
  cancelReason: PropTypes.string,
  onChange: PropTypes.func,
  onTextAreaChange: PropTypes.func,
};

export default CancellationReason;
