import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.lifestyle.lightBlue,
    },
    overflow: 'hidden',
  },
}));

const NavLink = ({ title, link, onClick }) => {
  const classes = useStyles();
  return (
    <Link to={link} className={classes.link} onClick={onClick}>
      {title}
    </Link>
  );
};

NavLink.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default NavLink;
