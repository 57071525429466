import React, { Fragment, useState } from 'react';
import SideDrawer from './components/SideDrawer';
import HeaderNav from './components/HeaderNav';
import SidebarContent from './components/SidebarContent';
import Backdrop from './components/Backdrop';

const Navigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const openDrawer = () => {
    setDrawerIsOpen(true);
  };
  const closeDrawer = () => {
    setDrawerIsOpen(false);
  };
  const sideBarHandler = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };
  const Sidebar = () => {
    if (drawerIsOpen) {
      return (
        <Fragment>
          <Backdrop onClick={closeDrawer} />
          <SideDrawer show={true}>
            <SidebarContent onNavigate={sideBarHandler} />
          </SideDrawer>
        </Fragment>
      );
    }
  };
  return (
    <Fragment>
      {Sidebar()}
      <HeaderNav openSideDrawer={openDrawer} />
    </Fragment>
  );
};

export default Navigation;
